<template>
  <keep-alive>
    <div class="wlzs_container">
      <div class="wlzs_top">
        <div class="wlzs_top_logo"><img src="@/img/networkConsultingRoom/编组11@2x.png" alt=""></div>
        <img class="wlzs_top_img" src="@/img/networkConsultingRoom/wlzsbanner.png" alt="">
      </div>
      <div class="technologicalProcess">
        <p class="technologicalProcess_title1">多端联动打通问诊、购药及患者管理全流程</p>
        <p class="technologicalProcess_title2">机构端后台+ 医生(APP端、PC端)+用户小程序端</p>
        <div class="technologicalProcess_item1">
          <div v-for="(item,index) in imgSrcList1" :key="index">
            <div class="technologicalProcess_item1_container" @mouseenter="item.show = true" @mouseleave="item.show = false">
              <p :style="{color: item.show ? '#fff' : '#333333'}" class="technologicalProcess_item1_title">{{ item.title }}</p>
              <p :style="{color: item.show ? '#fff' : '#8B8A99'}" class="technologicalProcess_item1_content">{{ item.content }}</p>
              <img :class="{img_shadow: item.show ? true : false}" :src="require(`@/img/networkConsultingRoom/${item.src}${item.show ? '2' : '1'}.png`)" alt="">
            </div>
          </div>
        </div>
        <div class="technologicalProcess_item2">
          <div v-for="(item,index) in imgSrcList2" :key="index">
            <div :style="{opacity: item.opcaty ? '0' : '1', cursor: item.opcaty ? '' : 'pointer'}" class="technologicalProcess_item2_container" @mouseenter="item.show = true" @mouseleave="item.show = false">
              <p :style="{color: item.show ? '#fff' : '#333333'}" class="technologicalProcess_item2_title">{{ item.title }}</p>
              <p :style="{color: item.show ? '#fff' : '#8B8A99'}" class="technologicalProcess_item2_content">{{ item.content }}</p>
              <img style="cursor: pointer;" :class="{img_shadow: item.show ? true : false}" :src="require(`@/img/networkConsultingRoom/${item.src}${item.show ? '2' : '1'}.png`)" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="wlzs_advantage">
        <div class="wlzs_advantage1">
          <div class="wlzs_advantage_line" />
          <div class="wlzs_advantage1_title_img">
            <img src="@/img/networkConsultingRoom/网络诊室4大优势@2x.png" alt="">
          </div>
          <p class="wlzs_advantage1_title">医生IP+品牌宣教+交易闭环+数据洞悉</p>
          <div class="wlzs_advantage_centent_title">
            <div class="wlzs_advantage_centent_title_img">
              <div>01</div>
              <img src="@/img/networkConsultingRoom/椭圆形@2x.png" alt="">
            </div>
            <div class="wlzs_advantage_centent_title_centent">
              <p>打造品牌医生IP</p>
              <p>医生团队承接粉丝流量达成转化，实现医生价值最大化</p>
            </div>
          </div>
          <div class="wlzs_advantage1_img">
            <img src="@/img/networkConsultingRoom/打造品牌医生IP@2x.png" alt="">
          </div>
        </div>
        <div class="wlzs_advantage2">
          <div class="wlzs_advantage_centent_title">
            <div class="wlzs_advantage_centent_title_img">
              <div>02</div>
              <img src="@/img/networkConsultingRoom/椭圆形@2x.png" alt="">
            </div>
            <div class="wlzs_advantage_centent_title_centent">
              <p>全链路品牌宣教</p>
              <p>从机构到医生延长至机构到医生到患者，链条更长影响更广</p>
            </div>
          </div>
          <div class="wlzs_advantage2_img">
            <img src="@/img/networkConsultingRoom/全链路品牌宣教@2x.png" alt="">
          </div>
        </div>
        <div class="wlzs_advantage4">
          <div class="wlzs_advantage_centent_title">
            <div class="wlzs_advantage_centent_title_img">
              <div>03</div>
              <img src="@/img/networkConsultingRoom/椭圆形@2x.png" alt="">
            </div>
            <div class="wlzs_advantage_centent_title_centent">
              <p>患者在线购药闭环</p>
              <p>为患者提供线上问诊、购药、订单跟踪、用药指引等功能形成交易闭环模式</p>
            </div>
          </div>
          <div class="wlzs_advantage4_img">
            <img src="@/img/networkConsultingRoom/患者在线购药闭环@2x.png" alt="">
          </div>
        </div>
        <div class="wlzs_advantage3">
          <div class="wlzs_advantage_centent_title">
            <div class="wlzs_advantage_centent_title_img">
              <div>04</div>
              <img style="transform: rotate(180deg);" src="@/img/networkConsultingRoom/椭圆形@2x.png" alt="">
            </div>
            <div class="wlzs_advantage_centent_title_centent">
              <p>全链路数据洞察</p>
              <p>全流程功能完备，整体链路数据跟踪，关键指标分析指引</p>
            </div>
          </div>
          <div class="wlzs_advantage3_img">
            <img src="@/img/networkConsultingRoom/全链路数据洞察@2x.png" alt="">
          </div>
        </div>
      </div>
      <div class="cooperation_mode">
        <div style="width: 1240px;margin: 0 auto 0;">
          <!-- <img class="cooperation_mode_background" src="@/img/networkConsultingRoom/分割背景@2x.png" alt=""> -->
          <div class="cooperation_mode_title_content">
            <p class="cooperation_mode_title1">合作模式</p>
            <p class="cooperation_mode_title2">多套餐收费模式，满足各种场景需求</p>
          </div>
          <img src="@/img/networkConsultingRoom/step1@2x.png" alt="">
          <p class="cooperation_mode_title3">请根据实际医生数量选取，每位医生账号占用1个坐席名额</p>
          <div class="sass_or_saas">
            <div class="sass_or_saas_1">
              <img src="@/img/networkConsultingRoom/SAAS标准版背景@2x.png" alt="">
              <p class="sass_or_saas_text1">SAAS标准版</p>
              <p class="sass_or_saas_text2">
                <span class="sass_or_saas_text2_1">¥19800</span>
                <span class="sass_or_saas_text2_2"> /年</span>
              </p>
              <p class="sass_or_saas_text3">3个接诊坐席</p>
              <p class="sass_or_saas_text4">新增席位5000元/个/年</p>
              <div class="sass_or_saas_bottom1">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>机构端后台</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生APP端</div>
                </div>
              </div>
              <div class="sass_or_saas_bottom2">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生PC端</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>用户小程序端</div>
                </div>
              </div>
            </div>
            <div class="sass_or_saas_2">
              <img src="@/img/networkConsultingRoom/SAAS专业版背景@2x.png" alt="">
              <p class="sass_or_saas_text1">SAAS专业版</p>
              <p class="sass_or_saas_text2">
                <span class="sass_or_saas_text2_1">¥72000</span>
                <span class="sass_or_saas_text2_2"> /年</span>
              </p>
              <p class="sass_or_saas_text3">20个接诊坐席</p>
              <p class="sass_or_saas_text4">新增席位3500元/个/年</p>
              <div class="sass_or_saas_bottom1">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>机构端后台</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生APP端</div>
                </div>
              </div>
              <div class="sass_or_saas_bottom2">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生PC端</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>用户小程序端</div>
                </div>
              </div>
            </div>
            <div class="sass_or_saas_3">
              <img src="@/img/networkConsultingRoom/SAAS标准版背景@2x1.png" alt="">
              <p class="sass_or_saas_text1">SAAS标准版</p>
              <p class="sass_or_saas_text2">
                <span class="sass_or_saas_text2_1">¥168000</span>
                <span class="sass_or_saas_text2_2"> /年</span>
              </p>
              <p class="sass_or_saas_text3">50个接诊坐席</p>
              <p class="sass_or_saas_text4">新增席位3000元/个/年</p>
              <div class="sass_or_saas_bottom1">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>机构端后台</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生APP端</div>
                </div>
              </div>
              <div class="sass_or_saas_bottom2">
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>医生PC端</div>
                </div>
                <div class="sass_or_saas_bottom_yaun">
                  <div />
                  <div>用户小程序端</div>
                  <img class="sass_or_saas_bottom_yaun_icon" src="@/img/networkConsultingRoom/icon_dulibushu@2x.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="independent_deployment">
            <img class="independent_deployment_background" src="@/img/networkConsultingRoom/独立部署版背景@2x.png" alt="">
            <div class="independent_deployment_text">
              <div class="independent_deployment_text1">
                <span>¥980000</span>
                <span>起</span>
              </div>
              <div class="independent_deployment_text2">
                不限坐席（医生账号）
              </div>
              <div class="independent_deployment_text3">
                <div class="sass_or_saas_bottom1">
                  <div class="sass_or_saas_bottom_yaun">
                    <div />
                    <div>机构端后台</div>
                  </div>
                  <div class="sass_or_saas_bottom_yaun">
                    <div />
                    <div>医生APP端</div>
                  </div>
                </div>
                <div class="sass_or_saas_bottom2">
                  <div class="sass_or_saas_bottom_yaun">
                    <div />
                    <div>医生PC端</div>
                  </div>
                  <div class="sass_or_saas_bottom_yaun">
                    <div />
                    <div>用户小程序端</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="@/img/networkConsultingRoom/step2@2x.png" alt="">
          <p class="cooperation_mode_title3">请根据是否已自有网院，若无医生是否需要多点执业到莲藕互联网医院选择套餐类型</p>
          <div class="service_package1">
            <div class="service_package1_left">
              <img src="@/img/networkConsultingRoom/icon_ziyingwangyuan@2x.png" alt="">
              <p>自营网院</p>
            </div>
            <img class="service_package1_center" src="@/img/networkConsultingRoom/矩形@2x.png" alt="">
            <div class="service_package1_right">
              <div class="service_package1_right_top">
                <div style="color: #333333;font-weight: 500;">项目名</div>
                <div>CA证书</div>
                <div>处方实名</div>
                <div>电子签名</div>
                <div style="border-right: none;">合理用药</div>
              </div>
              <div class="service_package1_right_bottom">
                <div>费用</div>
                <div>800元/人/年</div>
                <div>0.2元/单</div>
                <div>0.1元/单</div>
                <div style="border-right: none;">0.2元/单</div>
              </div>
            </div>
          </div>
          <div class="service_package2">
            <div class="service_package2_left">
              <img src="@/img/networkConsultingRoom/icon_feiduodianwangyuan@2x.png" alt="">
              <p>非多点执业至</p>
              <p>莲藕网院</p>
            </div>
            <img class="service_package2_center" src="@/img/networkConsultingRoom/矩形@2x.png" alt="">
            <div class="service_package2_right">
              <div class="service_package2_right_title">
                <div />
                <div>
                  <span>处方费用   </span>
                  <span>2元/单</span>
                </div>
              </div>
              <div class="service_package2_right_center">
                莲藕医生合规电子处方服务流程
              </div>
              <div class="service_package2_right_bottom">包含不仅限于专业医生开方、执业药师审方、处方风控、处方复检、质控等电子签名等服务</div>
            </div>
          </div>
          <div class="service_package3">
            <div class="service_package3_left">
              <img src="@/img/networkConsultingRoom/icon_duodianwangyuan@2x.png" alt="">
              <p>多点执业至</p>
              <p>莲藕网院</p>
            </div>
            <img class="service_package3_center" src="@/img/networkConsultingRoom/矩形@2x.png" alt="">
            <div class="service_package3_right">
              <div class="service_package3_right_top">
                <div style="color: #333333;font-weight: 500;">项目名</div>
                <div style="flex-direction: column;flex: 1.5;">
                  <p>多点管理费</p>
                  <p>(含1对1CA证书、保险)</p>
                </div>
                <div>处方实名</div>
                <div>电子签名</div>
                <div>药师云服务</div>
                <div style="border-right: none;">合理用药</div>
              </div>
              <div class="service_package3_right_bottom">
                <div style="flex: 1;border-right: 1px solid #F4F4F4;">费用</div>
                <div style="flex: 1.5;border-right: 1px solid #F4F4F4;">1000元/人/年</div>
                <div style="flex: 4;" class="service_package3_right_bottom_enter">
                  <div style="border-right: none;">
                    <div style="border-right: 1px solid #F4F4F4;">0.2元/单</div>
                    <div style="border-right: 1px solid #F4F4F4;">0.1元/单</div>
                    <div style="border-right: 1px solid #F4F4F4;">0.4元/单</div>
                    <div>0.2元/单</div>
                  </div>
                  <div style="border-top: 1px solid #F4F4F4;border-radius: 0 0 27px 0">套餐包 6000元/万单</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service_system">
        <img class="service_system_title_img" src="@/img/networkConsultingRoom/编组@2x.png" alt="">
        <p class="service_system_title">不仅是系统提供商，更是行业服务提供商</p>
        <div class="service_system_introduce">
          <div class="service_system_introduce_1">
            <div>行业专家</div>
            <img src="@/img/networkConsultingRoom/01@2x.png" alt="">
          </div>
          <div class="service_system_introduce_2">
            <div>产品专家</div>
            <img src="@/img/networkConsultingRoom/01@2x.png" alt="">
          </div>
          <div class="service_system_introduce_3">
            <div>技术专家</div>
            <img src="@/img/networkConsultingRoom/01@2x.png" alt="">
          </div>
          <div class="service_system_introduce_4">
            <div>运营专家</div>
            <img src="@/img/networkConsultingRoom/01@2x.png" alt="">
          </div>
        </div>
        <div class="service_system_content">
          <img style="z-index: 1;" src="@/img/networkConsultingRoom/矩形@2x1.png" alt="">
          <div class="service_system_content0">
            <div class="service_system_content1">
              <div />
              <div>根据企业情况结合发展趋势提供一对一套餐解决方案</div>
            </div>
            <div class="service_system_content2">
              <div />
              <div>深入了解企业需求，配置合理的产品方案</div>
            </div>
            <div class="service_system_content3">
              <div />
              <div>按实际要求提供专业化定制开发满足企业多场景应用</div>
            </div>
            <div class="service_system_content4">
              <div />
              <div>定期系统使用培训，数据分析指引，升级维护服务</div>
            </div>
          </div>
        </div>
      </div>
      <div class="supply_chain">
        <img src="@/img/networkConsultingRoom/一站式供应链服务@2x.png" alt="">
      </div>
      <div class="solution">
        <div class="solution_container">
          <div class="solution_left">
            <div>获取您的专属解决方案</div>
            <input v-model="phone" placeholder="请留下您的姓名" type="text">
            <input v-model="address" class="phone" type="number" placeholder="请留下您的联系电话">
            <div @click="submit">获取专业方案</div>
          </div>
          <div class="solution_line" />
          <div class="solution_right">
            <div>
              <img src="@/img/networkConsultingRoom/erCode.png" alt="">
            </div>
            <div>
              <div>
                <div>莲藕科技</div>
                <div>医药数字化基础设施及服务提供商</div>
              </div>
              <div>
                <img src="@/img/networkConsultingRoom/icon_arrows@2x.png" alt="">
                <div>扫码添加专属顾问</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container_bottom">
        <div class="container_bottom_log">
          <img src="@/img/networkConsultingRoom/莲藕健康LOGO@2x.png" alt="">
        </div>
        <div class="container_bottom_content">
          <p>广东莲藕健康科技有限公司成立于2017年，4年来专注移动互联网医疗、智慧买药创新服务模式的建设与推行，致力成为医药数字化基础设施及服务提供商。</p>
          <p>企业融合互联网技术、专业医疗服务、营销与运营服务、建立莲藕科技、莲藕医生、莲藕药业三大业务板块。核心团队源自2011年成立的医药电商康爱多的创始团队，医药领域经验丰富，是国内医药电商的第一批创业团队。</p>
          <p>成立以来，企业秉承“连接健康，稻合共生”的经营理念，以“科技创新健康服务，让每一个人都能享受互联网的普惠医疗”为愿景与使命。</p>
          <div class="container_bottom_adderss">
            <img src="@/img/networkConsultingRoom/icon_address@2x.png" alt="">
            <div>广州市白云区梅岗路1号大院201栋3楼A16单元</div>
          </div>
          <div class="container_bottom_phone">
            <img src="@/img/networkConsultingRoom/icon_phone@2x.png" alt="">
            <div>202-31235370</div>
          </div>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      phone: '',
      address: '',
      imgSrcList1: [
        { id: 1, src: '患者管理@2x', show: false, title: '患者管理', content: '辅助机构对问诊患者管理及行为数据分析' },
        { id: 2, src: '接诊模式@2x', show: false, title: '接诊模式', content: '多接诊模式，支持个人接诊及医生团队接诊' },
        { id: 3, src: '医生直播@2x', show: false, title: '医生直播', content: '医生可通过直播形式宣教培育私域用户' },
        { id: 4, src: '电子处方@2x', show: false, title: '电子处方', content: '可在线开具互联网医院的合规电子处方' },
        { id: 5, src: '药店管理@2x', show: false, title: '药店管理', content: '支持配置多家在线药店，实现在线药品进销存管理' },
        { id: 6, src: '诊疗风控@2x', show: false, title: '诊疗风控', content: '诊疗环节实施实时监控、风险拦截' },
        { id: 7, src: '预设话术@2x', show: false, title: '预设话术', content: '支持机构话术及个人话术配置，提升服务效率' }
      ],
      imgSrcList2: [
        { id: 8, src: '知识付费@2x', show: false, opcaty: false, title: '知识付费', content: '可设置医生在线咨询及图文资讯的费用' },
        { id: 9, src: '在线问诊@2x', show: false, opcaty: false, title: '在线问诊', content: '患者通过小程序方便快捷在线问诊及购药' },
        { id: 10, src: '权限隔离@2x', show: false, opcaty: false, title: '权限隔离', content: '诊室可设置对应业务人员，数据隔离管理' },
        { id: 11, src: '在线问诊@2x', show: false, opcaty: true, title: '在线问诊', content: '患者通过小程序方便快捷在线问诊及购药' },
        { id: 12, src: '在线问诊@2x', show: false, opcaty: true, title: '在线问诊', content: '患者通过小程序方便快捷在线问诊及购药' },
        { id: 13, src: '在线问诊@2x', show: false, opcaty: true, title: '在线问诊', content: '患者通过小程序方便快捷在线问诊及购药' },
        { id: 14, src: '在线问诊@2x', show: false, opcaty: true, title: '在线问诊', content: '患者通过小程序方便快捷在线问诊及购药' }
      ]
    }
  },
  methods: {
    submit() {
      const data = {
        UserName: this.address,
        Phone: this.phone
      }
      this.$api.AddCooperationCommonMessage(data).then(res => {
        if (res.code === 1) {
          this.phone = ''
          this.address = ''
          Toast.success('提交成功')
        } else {
          Toast.success('提交失败，请稍后再试')
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.wlzs_container {
  width: 100%;
  font-size: 0;
  margin: 0 auto;
  min-width: 1240px;
  // 图片阴影
  .img_shadow {
    border-radius: 60px;
    background: #22C6B2;
    box-shadow: 0px 3px 13px 0px rgba(21, 119, 107, 0.28);
  }
  img {
    width: 100%;
  }
  .wlzs_top {
    .wlzs_top_logo {
      margin: 0 auto;
      width: 1240px;
      position: relative;
      img {
        top: 40px;
        left: 0;
        position: absolute;
        width: 200px;
      }
    }
    .wlzs_top_img {
      display: block;
    }
  }
  .technologicalProcess {
    position: relative;
    height: 784px;
    width: 100%;
    background-image: url('../..//img/networkConsultingRoom/technologicalProcess.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .technologicalProcess_title1 {
      padding-top: 80px;
      font-size: 36px;
      font-family: PingFangHK-Semibold, PingFangHK;
      color: #333333;
      font-weight: 600;
    }
    .technologicalProcess_title2 {
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      letter-spacing: 1px;
      font-size: 16px;
      margin-top: 10px;
      color: #333333;
    }
    .technologicalProcess_item1 {
      justify-content: center;
      text-align: left;
      margin-top: 38px;
      display: flex;
      .technologicalProcess_item1_container {
          padding: 0 20px 0 15px;
          z-index: 2;
          box-sizing: border-box;
          margin-right: 15px;
          width: 159px;
          height: 230px;
          cursor: pointer;
          position: relative;
        .technologicalProcess_item1_title {
          font-size: 16px;
          font-family: PingFangHK-Medium, PingFangHK;
          letter-spacing: 1px;
          font-weight: 500;
          position: relative;
          top: 98px;
          letter-spacing: 1px;
        }
        .technologicalProcess_item1_content {
          font-size: 12px;
          letter-spacing: 1px;
          width: 123px;
          position: relative;
          top: 124px;
          color: #8B8A99;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
        }
        img {
          top: 0;
          z-index: -1;
          left: 0;
          position: absolute;
        }
      }
    }
    .technologicalProcess_item2 {
      text-align: left;
      margin-top: 38px;
      display: flex;
      justify-content: center;
      .technologicalProcess_item2_container {
          padding: 0 20px 0 15px;
          box-sizing: border-box;
          margin-right: 15px;
          width: 159px;
          z-index: 2;
          height: 230px;
          position: relative;
        .technologicalProcess_item2_title {
          font-size: 16px;
          font-family: PingFangHK-Medium, PingFangHK;
          letter-spacing: 1px;
          position: relative;
          top: 98px;
          color: #333333;
          font-weight: 500;
          letter-spacing: 1px;
        }
        .technologicalProcess_item2_content {
          font-size: 12px;
          letter-spacing: 1px;
          position: relative;
          top: 124px;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          width: 123px;
          color: #8B8A99;
        }
        img {
          top: 0;
          left: 0;
          z-index: -1;
          position: absolute;
        }
      }
    }
  }
  .wlzs_advantage {
    text-align: center;
    width: 1240px;
    margin: 0 auto 90px;
    height: 100%;
    .wlzs_advantage_centent_title {
      display: flex;
      height: 53px;
      .wlzs_advantage_centent_title_img {
        position: relative;
        font-size: 26px;
        color: #FFFFFF;
        height: 48px;
        width: 53px;
        line-height: 48px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
          img {
            position: absolute;
            width: 53px;
            z-index: -1;
            top: 0;
            display: block;
            height: 53px;
          }
          div {
            height: 53px;
            position: relative;
          }
        }
    }
    .wlzs_advantage_centent_title_centent {
      margin-left: 10px;
      p:nth-child(1) {
        font-size: 22px;
        text-align: left;
        font-family: PingFangHK-Semibold, PingFangHK;
        font-weight: 600;
        color: #333333;
      }
      p:nth-child(2) {
        font-weight: 400;
        color: #AAAAAA;
        font-family: PingFangHK-Regular, PingFangHK;
        font-size: 14px;
      }
    }
    .wlzs_advantage1 {
      position: relative;
      margin: 0 auto 0;
      .wlzs_advantage_line {
        width: 2px;
        top: 229px;
        left: 25px;
        height: 1890px;
        position: absolute;
        background-color: rgba(34, 198, 178, 0.3);
      }
      .wlzs_advantage1_title_img {
        margin: 82px auto 0;
        width: 300px;
        font-size: 16px;
        height: 53px;
        vertical-align: middle;
      }
      .wlzs_advantage1_title {
        font-family: PingFangHK-Regular, PingFangHK;
        letter-spacing: 1px;
        color: #333333;
        font-weight: 400;
        font-size: 22px;
        margin: 21px 0 82px;
      }
      .wlzs_advantage1_img {
        margin-top: 77px;
        margin-left: 35px;
        padding-left: 50px;
        width: 1138px;
      }
    }
    .wlzs_advantage2 {
      margin-top: 112px;
      .wlzs_advantage1_title_img {
        width: 300px;
        height: 53px;
      }
      .wlzs_advantage1_title {
        font-family: PingFangHK-Regular, PingFangHK;
        letter-spacing: 1px;
        color: #333333;
        font-weight: 400;
        font-size: 16px;
        margin: 21px 0 82px;
      }
      .wlzs_advantage2_img {
        margin-top: 77px;
        width: 1200px;
        box-sizing: border-box;
        padding-left: 50px;
      }
    }
    .wlzs_advantage3 {
      margin-top: 80px;
      .wlzs_advantage1_title_img {
        width: 300px;
        height: 53px;
      }
      .wlzs_advantage1_title {
        font-family: PingFangHK-Regular, PingFangHK;
        letter-spacing: 1px;
        color: #333333;
        font-weight: 400;
        font-size: 16px;
        margin: 21px 0 82px;
      }
      .wlzs_advantage3_img {
        margin-top: 77px;
        width: 1000px;
        padding-left: 170px;
      }
    }
    .wlzs_advantage4 {
      margin-top: 70px;
      .wlzs_advantage4_title_img {
        width: 300px;
        height: 53px;
      }
      .wlzs_advantage4_title {
        font-family: PingFangHK-Regular, PingFangHK;
        letter-spacing: 1px;
        color: #333333;
        font-weight: 400;
        font-size: 16px;
        margin: 21px 0 82px;
      }
      .wlzs_advantage4_img {
        margin-top: 77px;
        padding-left: 140px;
        width: 1000px;
      }
    }
  }
  .cooperation_mode {
    background-image: url('../../img/networkConsultingRoom/分割背景@2x.png');
    background-size: contain;
    background-repeat: repeat;
    position: relative;
    z-index: -1;
    padding-bottom: 100px;
    min-width: 1240px;
    padding-top: 97px;
    box-sizing: border-box;
    .cooperation_mode_background {
      height: 2092px;
      top: 0;
      left: 0;
      z-index: -1;
      position: absolute;
    }
    .cooperation_mode_title_content {
      // padding: 10px;
    }
    .cooperation_mode_title1 {
      font-size: 36px;
      height: 50px;
      line-height: 50px;
      font-weight: 600;
      color: #333333;
      font-family: PingFangHK-Semibold, PingFangHK;
    }
    .cooperation_mode_title2 {
      margin: 6 0 27px;
      font-size: 16px;
      height: 22px;
      font-weight: 600;
      color: #333333;
      font-family: PingFangHK-Semibold, PingFangHK;
    }
    .cooperation_mode_title3 {
      text-align: left;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      font-size: 14px;
      color: #6E7A79;
      letter-spacing: 1px;
    }
    .sass_or_saas {
      display: flex;
      margin-top: 64px;
      .sass_or_saas_text1 {
        font-size: 26px;
        margin-top: 52px;
        font-family: PingFangHK-Semibold, PingFangHK;
        font-weight: 600;
        color: #120A27;
      }
      .sass_or_saas_text2 {
        margin: 74px 0 15px;
        .sass_or_saas_text2_1 {
          font-size: 52px;
          font-weight: bold;
          color: #FB6868;
          font-family: DINAlternate-Bold, DINAlternate;
        }
        .sass_or_saas_text2_2 {
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          color: #AAAAAA;
          font-size: 16px;
        }
      }
      .sass_or_saas_text3 {
        font-weight: 600;
        color: #A9A9A9;
        font-size: 16px;
        font-family: PingFangHK-Semibold, PingFangHK;
      }
      .sass_or_saas_text4 {
        font-size: 16px;
        margin-top: 15px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #333333;
      }
      .sass_or_saas_bottom_yaun {
        width: 272px;
        display: flex;
        align-items: center;
        position: relative;
        // justify-content: space-around;
        div:nth-child(1) {
          width: 7px;
          height: 7px;
          margin-right: 13px;
          border-radius: 50%;
          background-color: #22C6B2;
        }
        div:nth-child(2) {
          font-size: 16px;
          font-family: PingFangHK-Regular, PingFangHK;
          color: #83909D;
          font-weight: 400;
        }
        .sass_or_saas_bottom_yaun_icon {
          position: absolute;
          width: 73px;
          left: 103px;
          top: -25px;
          height: 28px;
          vertical-align: middle;
        }
      }
      .sass_or_saas_bottom1 {
        width: 272px;
        display: flex;
        margin: 40px 0 19px;
        margin-right: auto;
        margin-left: auto;
      }
      .sass_or_saas_bottom2 {
        width: 272px;
        display: flex;
        margin-right: auto;
        margin-left: auto;
      }
      .sass_or_saas_1 {
        width: 370px;
        height: 478px;
        position: relative;
        img {
          z-index: -1;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .sass_or_saas_2 {
        width: 370px;
        margin: 0 45px;
        height: 478px;
        position: relative;
        img {
          z-index: -1;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .sass_or_saas_3 {
        width: 370px;
        height: 478px;
        position: relative;
        img {
          z-index: -1;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .independent_deployment {
      height: 242px;
      position: relative;
      margin-top: 30px;
      .independent_deployment_background {
        top: 0;
        left: 0;
        z-index: -1;
        position: absolute;
      }
      .independent_deployment_text {
        position: relative;
        top: 139px;
        width: 100%;
        justify-content: space-between;
        display: flex;
        .independent_deployment_text1 {
          flex: 1;
          border-right: 1px dashed #D0D0D0;
          span:nth-child(1) {
            font-size: 52px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #FB6868;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-family: PingFangHK-Regular, PingFangHK;
            font-weight: 400;
            color: #AAAAAA;
          }
        }
        .independent_deployment_text2 {
          display: flex;
          flex: 1;
          justify-content: center;
          font-size: 20px;
          border-right: 1px dashed #D0D0D0;
          color: #A9A9A9;
          font-weight: 400;
          font-family: PingFangHK-Regular, PingFangHK;
          align-items: center;
        }
        .independent_deployment_text3 {
          flex: 1;
          .sass_or_saas_bottom_yaun {
            width: 271px;
            display: flex;
            align-items: center;
            div:nth-child(1) {
              width: 7px;
              height: 7px;
              margin-right: 13px;
              border-radius: 50%;
              background-color: #22C6B2;
            }
            div:nth-child(2) {
              font-size: 16px;
              font-family: PingFangHK-Regular, PingFangHK;
              color: #83909D;
              font-weight: 400;
            }
          }
          .sass_or_saas_bottom1 {
            width: 271px;
            display: flex;
            margin: 0 0 19px;
            margin-right: auto;
            margin-left: auto;
          }
          .sass_or_saas_bottom2 {
            width: 271px;
            display: flex;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    }
    .service_package1 {
      height: 155px;
      margin-top: 55px;
      border-radius: 27px;
      background-color: #fff;
      display: flex;
      .service_package1_left {
        display: flex;
        width: 200px;
        flex-direction: column;
        box-sizing: border-box;
        padding: 36px 0;
        img {
          margin-left: auto;
          margin-right: auto;
          width: 40px;
          margin-bottom: 5px;
          height: 40px;
        }
        p {
          font-family: PingFangHK-Medium, PingFangHK;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
        }
      }
      .service_package1_center {
        width: 1px;
      }
      .service_package1_right {
        flex: 1;
        padding: 26px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .service_package1_right_top {
          border-bottom: 1px solid #F4F4F4;
          height: 50%;
          align-items: center;
          display: flex;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-right: 1px solid #F4F4F4;
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: #5D5D5D;
            font-family: PingFangHK-Medium, PingFangHK;
          }
        }
        .service_package1_right_bottom {
          display: flex;
          height: 50%;
          align-items: center;
          div {
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #F4F4F4;
            font-size: 14px;
            font-weight: 400;
            color: #5D5D5D;
            font-family: PingFangHK-Medium, PingFangHK;
          }
        }
      }
    }
    .service_package2 {
      height: 186px;
      margin: 21px 0;
      border-radius: 27px;
      background-color: #fff;
      display: flex;
      .service_package2_left {
        display: flex;
        width: 200px;
        flex-direction: column;
        box-sizing: border-box;
        padding: 36px 0;
        img {
          margin-left: auto;
          margin-right: auto;
          width: 40px;
          margin-bottom: 5px;
          height: 40px;
        }
        p {
          font-family: PingFangHK-Medium, PingFangHK;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
        }
      }
      .service_package2_center {
        width: 1px;
      }
      .service_package2_right {
        padding-top: 44px;
        padding-left: 69px;
        box-sizing: border-box;
        flex: 1;
        .service_package2_right_title {
          display: flex;
          align-items: center;
          div:nth-child(1) {
            width: 6px;
            margin-right: 14px;
            border-radius: 50%;
            background-color: #585858;
            height: 6px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: PingFangHK-Semibold, PingFangHK;
            font-weight: 600;
            span:nth-child(1) {
              color: #585858;
            }
            span:nth-child(2) {
              color: #FB6868;
            }
          }
        }
        .service_package2_right_center {
          margin: 18px 0;
          font-size: 14px;
          color: #ACACAC;
          text-align: left;
        }
        .service_package2_right_bottom {
          font-size: 14px;
          color: #585858;
          text-align: left;
        }
      }
    }
    .service_package3 {
      height: 186px;
      display: flex;
      border-radius: 27px;
      background-color: #fff;
      .service_package3_left {
        display: flex;
        width: 200px;
        flex-direction: column;
        box-sizing: border-box;
        padding: 36px 0;
        img {
          margin-left: auto;
          margin-right: auto;
          width: 40px;
          margin-bottom: 5px;
          height: 40px;
        }
        p {
          font-family: PingFangHK-Medium, PingFangHK;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
        }
      }
      .service_package3_center {
        width: 1px;
      }
      .service_package3_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        .service_package3_right_top {
          border-bottom: 1px solid #F4F4F4;
          flex: 1;
          align-items: center;
          display: flex;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-right: 1px solid #F4F4F4;
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: #5D5D5D;
            font-family: PingFangHK-Medium, PingFangHK;
          }
        }
        .service_package3_right_bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 2;
          div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-family: PingFangHK-Medium, PingFangHK;
            color: #5D5D5D;
            // border-right: 1px solid #F4F4F4;
            font-size: 14px;
          }
          .service_package3_right_bottom_enter {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  .service_system {
    padding-top: 100px;
    background-color: #fff;
    height: 603px;
    width: 100%;
    box-sizing: border-box;
    .service_system_title_img {
      width: 299px;
    }
    .service_system_title {
      font-weight: 400;
      color: #333333;
      margin: 12px 0 89px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 16px;
    }
    .service_system_introduce {
      display: flex;
      position: relative;
      justify-content: center;
      padding-left: 63px;
      box-sizing: border-box;
      .service_system_introduce_1 {
        position: relative;
        div {
          top: 43%;
          width: 100%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .service_system_introduce_2 {
        position: relative;
        margin-left: 69px;
        div {
          width: 100%;
          top: 43%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .service_system_introduce_3 {
        position: relative;
        margin-left: 69px;
        div {
          width: 100%;
          top: 43%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .service_system_introduce_4 {
        position: relative;
        margin: 0 69px;
        div {
          width: 100%;
          top: 43%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      div {
        position: absolute;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #FFFFFF;
        font-weight: 600;
      }
      img {
        width: 198px;
      }
    }
    .service_system_content {
      margin: 0 auto;
      width: 1079px;
      height: 174px;
      display: flex;
      position: relative;
      img {
        z-index: -1;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
      }
    }
    .service_system_content0 {
      display: flex;
      width: 100%;
      z-index: 1;
      align-items: center;
      .service_system_content1 {
        font-size: 14px;
        flex: 1;
        div:nth-child(1) {
          width: 27px;
          height: 3px;
          margin: 0 auto 12px;
          background: #FFB433;
          border-radius: 2px;
        }
        div:nth-child(2) {
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 14px;
          color: #333333;
          font-weight: 400;
          width: 150px;
          text-align: left;
          margin: 0 auto;
        }
      }
      .service_system_content2 {
        font-size: 14px;
        flex: 1;
        div:nth-child(1) {
          width: 27px;
          margin: 0 auto 12px;
          height: 3px;
          background: #FE9494;
          border-radius: 2px;
        }
        div:nth-child(2) {
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 14px;
          color: #333333;
          text-align: left;
          margin: 0 auto;
          font-weight: 400;
          width: 150px;
        }
      }
      .service_system_content3 {
        font-size: 14px;
        flex: 1;
        div:nth-child(1) {
          width: 27px;
          margin: 0 auto 12px;
          height: 3px;
          background: #94C9FE;
          border-radius: 2px;
        }
        div:nth-child(2) {
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 14px;
          color: #333333;
          width: 150px;
          margin: 0 auto;
          text-align: left;
          font-weight: 400;
        }
      }
      .service_system_content4 {
        font-size: 14px;
        flex: 1;
        div:nth-child(1) {
          width: 27px;
          margin: 0 auto 12px;
          height: 3px;
          background: #BEBBFF;
          border-radius: 2px;
        }
        div:nth-child(2) {
          width: 150px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 14px;
          text-align: left;
          color: #333333;
          margin: 0 auto;
          font-weight: 400;
        }
      }
    }
  }
  .supply_chain {
    min-width: 1240px;
    img {
      display: block;
    }
    // margin-top: 53px;
  }
  .solution {
    height: 439px;
    min-width: 1240px;
    background-color: #fff;
    position: relative;
    .solution_background {
      width: 1306px;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
    .solution_container {
      background-image: url('../../img/networkConsultingRoom/矩形@2x2.png');
      background-repeat: repeat;
      background-size: contain;
      display: flex;
      margin: 0 auto;
      width: 1210px;
      height: 100%;
      align-items: center;
      .solution_left {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        div:nth-child(1) {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #333333;
          font-weight: 500;
        }
        input:nth-child(2) {
          font-size: 19px;
          width: 393px;
          height: 40px;
          text-align: center;
          border: none;
          line-height: 19px;
          box-sizing: border-box;
          padding: 0 10px;
          margin: 19px auto 16px;
          border-radius: 4px;
          outline: none;
          color: #A8ABB3;
          background-color: #F5F7FA;
        }
        input::-webkit-input-placeholder {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        input::-moz-input-placeholder {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        input::-ms-input-placeholder {
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        .phone::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
        .phone::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input:nth-child(3) {
          height: 40px;
          border: none;
          text-align: center;
          padding: 0 10px;
          box-sizing: border-box;
          width: 393px;
          border-radius: 4px;
          font-size: 19px;
          color: #A8ABB3;
          line-height: 19px;
          outline: none;
          margin: 0 auto 31px;
          background-color: #F5F7FA;
        }
        div:nth-child(4) {
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          width: 393px;
          margin: 0 auto;
          cursor: pointer;
          background: linear-gradient(173deg, #FE9A3F 0%, #FF6C37 100%);
          font-family: PingFangSC-Medium, PingFang SC;
          color: #FFFFFF;
          font-weight: 600;
        }
      }
      .solution_line {
        background: #EBEEF5;
        width: 1px;
        height: 250px;
      }
      .solution_right {
        flex: 1.33;
        display: flex;
        justify-content: center;
        div:nth-child(1) {
          margin-right: 28px;
          img {
            width: 214px;
            height: 214px;
          }
        }
        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div:nth-child(1) {
            display: flex;
            flex-direction: column;
            div:nth-child(1) {
              text-align: left;
              font-size: 28px;
              font-style: italic;
              margin-bottom: 13px;
              line-height: 28px;
            }
            div:nth-child(2) {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              font-size: 20px;
              color: #A8ABB3;
            }
          }
          div:nth-child(2) {
            display: flex;
            flex-direction: row;
            img {
              width: 58px;
              margin-right: 16px;
              height: 32px;
            }
            div {
              flex: 1;
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .container_bottom {
    box-sizing: border-box;
    min-width: 1240px;
    background: #292933;
    margin: 0 auto;
    padding: 133px 0 100px;
    .container_bottom_log {
      font-size: 40px;
      margin-bottom: 49px;
      color: #fff;
      img {
        width: 255px;
        height: 59px;
      }
    }
    .container_bottom_content {
      line-height: 78px;
      margin: 0 auto;
      color: #FFFFFF;
      margin-bottom: 49px;
      width: 1240px;
      text-align: left;
      font-size: 14px;
      font-family: PingFangHK-Regular, PingFangHK;
      p:nth-child(2) {
        line-height: 28.8px;
      }
    }
    .container_bottom_adderss {
      color: #FFFFFF;
      text-align: left;
      margin: 49px auto 0;
      font-size: 14px;
      margin-bottom: 14px;
      line-height: 14px;
      align-items: center;
      display: flex;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      img {
        margin-right: 15px;
        width: 13px;
        height: 15px;
      }
    }
    .container_bottom_phone {
      display: flex;
      color: #FFFFFF;
      align-items: center;
      text-align: left;
      font-size: 14px;
      line-height: 14px;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      img {
        width: 13px;
        margin-right: 015px;
        height: 15px;
      }
    }
  }
}
</style>
